import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/LayoutComponent';
import ArticlePreview from '../components/ArticlePreview';
import sanitizeHtml from 'sanitize-html';
import Seo from '../components/SeoComponent';
//import Banner from '../components/supportedPhones/Banner';

class SupportedPhones extends React.Component {
    render() {
        const posts = get(this, 'props.data.allposts.nodes');
        const brands = get(this, 'props.data.brands.nodes');
        const metaTags = get(this, 'props.data.metaTags');
        const metaTitle =
            metaTags && metaTags.title ? sanitizeHtml(metaTags.title) : 'Phone Insurance | Supported Phones ';
        const metaDescription =
            metaTags && metaTags.description
                ? sanitizeHtml(metaTags.description)
                : 'The full list of phones eligible for Asurion Mobile+ smartphone insurance. We provide cover for most Apple and Samsung smartphones up to 48 months old.';
        const ogImage = metaTags && metaTags.ogImage ? metaTags.ogImage.file.url : '';

        const location = this.props && this.props.location ? sanitizeHtml(this.props.location) : '';
        // const slug = 'Apple';
        const slug = this.props.pageContext.slug ? this.props.pageContext.slug : 'Apple';
        const domainUrl = '';

        const canonicalUrl =
            this.props && this.props.pageContext && this.props.pageContext.canonicalPath
                ? domainUrl + this.props.pageContext.canonicalPath
                : domainUrl + this.props.path;

        return (
            <Layout
                location={location}
                subcomponent={
                    <>
                        <Seo
                            title={metaTitle}
                            description={metaDescription}
                            image={ogImage}
                            ogImage={ogImage}
                            canonical={canonicalUrl}
                        />

                        <h1 style={{ color: '#8223D2', marginLeft: '3%' }}> Supported Phones</h1>
                        
                        <ArticlePreview posts={posts} brands={brands} activeBrand={slug} />
                    </>
                }
            />
        );
    }
}

export default SupportedPhones;

export const pageQuery = graphql`
    query HomeQuery2 {
        allposts: allContentfulDeviceDetailPage(
            sort: { fields: [], order: DESC }
            filter: { node_locale: { eq: "en-US" } }
        ) {
            nodes {
                brand
                brandName
                updatedAt
                title
                slug
                reviewVideo {
                    file {
                        url
                    }
                    gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, width: 65)
                }

                variants
            }
        }
        brands: allContentfulDeviceDetailPage(filter: { brandName: {} }) {
            nodes {
                brandName
            }
        }
        metaTags: contentfulMetaTags(title: { eq: "Supported Phones | Asurion Mobile+" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
    }
`;
